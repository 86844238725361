import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

const SkeletonLoader = () => {
  return (
    <div className="w-[1050px] m-auto flex gap-5 mt-[3em]">
      <Stack spacing={1}>
        <Skeleton variant="rectangular" width={280} height={200} />
        <Skeleton variant="rounded" width={280} height={500} />
        <Skeleton variant="rounded" width={280} height={500} />
      </Stack>
      <Stack spacing={1} sx={{ width: "100%" }}>
        <Skeleton variant="rounded" height={300} />
        <Skeleton variant="rounded" height={250} />
        <Skeleton variant="rounded" height={300} />
      </Stack>
    </div>
  );
};

export default SkeletonLoader;
