import React, { Suspense } from "react";
import SkeletonLoader from "../../components/Loaders/SkeletonLoader";

const SuspenseWrapper = ({ LazyComponent }) => {
  return (
    <Suspense fallback={<SkeletonLoader />}>
      <LazyComponent />
    </Suspense>
  );
};

export default SuspenseWrapper;
