const initialState = {
  monitorContents: [],
};

const monitorReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case "GET_MONITOR_CONTENT":
      return {
        ...state,
        monitorContents: action.monitorContent,
      };

    default:
      return state;
  }
};

export default monitorReducer;
