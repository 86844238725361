import { useState } from "react";
import { Controller } from "react-hook-form";

// Components
import {
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import Select, { createFilter, components } from "react-select";
import Rating from "../Rating/Rating";

// Utils
import { FixedSizeList as List } from "react-window";
import { styled } from "@mui/material/styles";

const CustomOption = ({ children, ...props }) => {
  const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
  const newProps = { ...props, innerProps: rest };
  return <components.Option {...newProps}>{children}</components.Option>;
};

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#001756",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#106ba3",
  },
});

function BpRadio(props) {
  return (
    <Radio
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

const StyledTitle = ({ children }) => (
  <Typography sx={{ fontSize: "1rem", color: "#001756" }}>
    {children}
  </Typography>
);

const TextFieldInput = ({ kind, error, field }) => (
  <TextField
    {...field}
    error={error}
    type={kind}
    variant="outlined"
    inputProps={{
      sx: {
        color: "#001756",
        padding: 1,
        fontSize: 17,
      },
    }}
  />
);

const MenuList = ({ options, children, maxHeight, getValue }) => {
  const height = 35;
  const [value] = getValue();
  const initialOffset = options.indexOf(value) * height;

  return (
    <List
      height={maxHeight}
      itemCount={children.length}
      itemSize={height}
      initialScrollOffset={initialOffset}
    >
      {({ index, style }) => <div style={style}>{children[index]}</div>}
    </List>
  );
};

export const TextFieldRHF = ({
  title,
  name,
  control,
  errors,
  kind,
  isRequired = false,
}) => {
  return (
    <div className="mb-2">
      <StyledTitle color="initial" variant="h6">
        {title}
      </StyledTitle>
      <TextFieldController
        isRequired={isRequired}
        name={name}
        control={control}
        errors={errors}
        kind={kind}
      />
    </div>
  );
};

export const NumberFieldRHF = ({
  title,
  name,
  control,
  errors,
  isRequired = false,
}) => {
  return (
    <>
      <StyledTitle color="initial" variant="h6">
        {title}
      </StyledTitle>
      <TextFieldController
        isRequired={isRequired}
        name={name}
        control={control}
        errors={errors}
        kind="number"
      />
    </>
  );
};

export const DateFieldRHF = ({
  title,
  name,
  control,
  errors,
  isRequired = false,
}) => {
  return (
    <div className="w-full">
      <StyledTitle color="initial" variant="h6">
        {title}
      </StyledTitle>
      <TextFieldController
        isRequired={isRequired}
        name={name}
        control={control}
        errors={errors}
        kind="date"
      />
    </div>
  );
};

export const TextFieldController = ({
  name,
  control,
  errors,
  kind,
  isRequired,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: isRequired }}
      render={({ field }) => (
        <TextFieldInput
          field={field}
          error={errors[name]?.type === "required"}
          kind={kind}
        />
      )}
    />
  );
};

export const RadioOptionsRHF = ({
  title,
  name,
  control,
  options,
  isDisabled = false,
}) => {
  return (
    <div className="flex items-baseline gap-5">
      <StyledTitle color="initial" variant="h6">
        {title}
      </StyledTitle>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <RadioGroup {...field} aria-label={name}>
            {options.map(({ label, value }) => (
              <FormControlLabel
                sx={{ color: "#001756", fontSize: 3 }}
                key={value}
                control={
                  <BpRadio disabled={isDisabled} sx={{ color: "#001756" }} />
                }
                label={label}
                value={label}
              />
            ))}
          </RadioGroup>
        )}
      />
    </div>
  );
};

export const YesNoFieldRHF = ({ title, name, control }) => (
  <RadioOptionsRHF
    title={title}
    name={name}
    control={control}
    options={[
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ]}
  />
);

export const SelectFieldRHF = ({
  name,
  title,
  control,
  options,
  isDisabled,
  isMulti = false,
  isRequired = false,
  defaultOption,
}) => {
  return (
    <div className="w-full mt-[1rem] h-auto">
      <Typography
        color="initial"
        variant="h6"
        sx={{ color: "#001756", fontSize: 17 }}
      >
        {title}
      </Typography>
      <Controller
        name={name}
        control={control}
        rules={{ required: isRequired }}
        render={({ field }) => (
          <Select
            {...field}
            isSearchable
            isClearable
            closeMenuOnSelect={!isMulti}
            filterOption={createFilter({ ignoreAccents: false })}
            components={{ MenuList, Option: CustomOption }}
            isDisabled={isDisabled}
            isMulti={isMulti}
            closeMenuOnScroll
            options={options}
            defaultValue={defaultOption}
            defaultInputValue={defaultOption?.label}
            placeholder="Select or Search"
            styles={{
              control: (base) => ({
                ...base,
                borderRadius: "none",
                padding: "7px 9px",
                marginTop: 3,
              }),
              option: (base) => ({
                ...base,
                cursor: "pointer",
                color: "#001756",
                "&:hover": {
                  transitionDelay: "60ms",
                  backgroundColor: "#deebff",
                  color: "#001756",
                },
              }),
            }}
          />
        )}
      />
    </div>
  );
};

export const RatingFieldRHF = ({ name, control, isDisabled, color }) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: true }}
      render={({ field: { onChange, value } }) => (
        <Rating
          isDisabled={isDisabled}
          high="High"
          low="Low"
          value={value}
          color={color}
          onChange={(_, newValue) => onChange(newValue)}
        />
      )}
    />
  );
};

export const MultipleTextFieldRHF = ({
  name,
  title,
  control,
  isDisabled = false,
  px,
  pt,
}) => {
  const [inputValue, setInputValue] = useState("");

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, ...rest } }) => (
        <div>
          {!isDisabled && (
            <TextField
              disabled={isDisabled}
              fullWidth
              variant="outlined"
              placeholder={`type ${title} ...`}
              rows={1}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyPress={(e) => {
                if (e.key !== "Enter" || !e.target.value) {
                  return;
                }
                onChange([{ value: e.target.value }, ...value]);
                setInputValue("");
              }}
              InputProps={{
                "aria-label": "title",
                endAdornment: (
                  <InputAdornment position="end">
                    {inputValue ? (
                      <AddCircleIcon
                        style={{
                          color: "#2ecc71",
                          cursor: "pointer",
                          fontSize: 21,
                        }}
                        onClick={() => {
                          setInputValue("");
                          onChange([{ value: inputValue }, ...value]);
                        }}
                      />
                    ) : null}
                  </InputAdornment>
                ),
                sx: {
                  color: "#001756",
                  fontSize: 17,
                },
              }}
              {...rest}
            />
          )}
          {value?.length > 0 && (
            <ul className="list-disc mt-3 pl-3 max-h-[200px">
              {value.map((val, index) => (
                <li
                  className="text-[#001756] hover:bg-backgroundSearchField rounded-md"
                  key={index}
                  onClick={() =>
                    onChange(value.filter((str) => str.value !== val.value))
                  }
                >
                  <div className="flex justify-between items-center cursor-pointer p-1">
                    <span>{val.value}</span>
                    <RemoveCircleOutlineIcon
                      style={{ fontSize: 15, color: "crimson" }}
                      onClick={() =>
                        onChange(value.filter((str) => str.value !== val.value))
                      }
                    />
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    />
  );
};
