// Components
import BasicCard from "../Card/BasicCard";
import { Typography } from "@mui/material";
import {
  MultipleTextFieldRHF,
  RatingFieldRHF,
  SelectFieldRHF,
  RadioOptionsRHF,
} from "./FieldComponents";
import ContainerCard from "../ContainerCard/ContainerCard";

// Utils
import {
  useFetchEvents,
  useFetchOpinionOv,
  useFetchValuationMethodologies,
} from "./hooks";

const FeedbackForm = ({ control, errors }) => {
  const [opinionOv] = useFetchOpinionOv();
  const [methodologies] = useFetchValuationMethodologies();
  const [events] = useFetchEvents();

  return (
    <div className="mt-[2em] pb-5">
      <Typography
        sx={{ flex: 1, color: "#001756", fontWeight: "bold" }}
        variant="h5"
        component="div"
      >
        Feedback
      </Typography>
      <Typography
        sx={{ flex: 1, color: "#001756" }}
        variant="subtitle2"
        gutterBottom
      >
        Perception of the investor on strategic considerations: Strategy, ESG &
        Management
      </Typography>
      <br />
      <BasicCard noBorder elevation={0} padding="1rem">
        <BasicCard
          elevation={0}
          title="Related event"
          titleBackground="#f5f5f5"
          titleColor="#001756"
          fontWeight={600}
          fontSize="17px"
        >
          <Typography
            sx={{ flex: 1, color: "#001756", fontSize: 15 }}
            variant="h6"
            gutterBottom
          >
            Is the call report related to an Event? If yes, which one?
          </Typography>
          <SelectFieldRHF
            title=""
            name="event"
            control={control}
            options={events}
            errors={errors}
          />
        </BasicCard>
        <br />
        <div className="mt-[1em] flex gap-5 justify-between flex-wrap">
          <ContainerCard
            style={{
              backgroundColor: "#1abc9c",
              color: "#FFF",
              marginBottom: 0,
            }}
            instruction="Please rate the quality of execution of the strategy of the company:"
            headerTitle="Strategy"
          >
            <RatingFieldRHF
              name="executionStrategy"
              control={control}
              color="#1abc9c"
            />
          </ContainerCard>
          <ContainerCard
            style={{
              backgroundColor: "#9b59b6",
              color: "#F1F1F1",
              marginBottom: 0,
            }}
            instruction="Please rate the ESG of the company:"
            headerTitle="ESG"
          >
            <RatingFieldRHF
              name="financialPosition"
              control={control}
              color="#9b59b6"
            />
          </ContainerCard>
          <ContainerCard
            style={{
              backgroundColor: "#3498db",
              color: "#F1F1F1",
              marginBottom: 0,
            }}
            instruction="Please rate the quality of the management team:"
            headerTitle="Management"
          >
            <RatingFieldRHF
              name="qualityManagement"
              control={control}
              color="#3498db"
            />
          </ContainerCard>
        </div>
        <br />
        <BasicCard
          elevation={0}
          title="Top investor perception"
          titleBackground="#f5f5f5"
          titleColor="#001756"
          fontWeight={600}
          fontSize={17}
        >
          <div className="flex gap-5 justify-between">
            <BasicCard
              noBorder
              elevation={0}
              title="Strengths"
              titleColor="#2ecc71"
              fontSize="15px"
            >
              <MultipleTextFieldRHF
                name="strengths"
                title="strengths"
                control={control}
              />
            </BasicCard>
            <BasicCard
              noBorder
              elevation={0}
              title="Weaknesses"
              titleColor="#e74c3c"
              fontSize="15px"
            >
              <MultipleTextFieldRHF
                name="weaknesses"
                title="weaknesses"
                control={control}
              />
            </BasicCard>
          </div>
        </BasicCard>
        <br />
        <BasicCard
          elevation={0}
          title="Influence on valuation"
          titleBackground="#f5f5f5"
          titleColor="#001756"
          fontWeight={600}
          fontSize={17}
        >
          <div className="flex gap-5 justify-between">
            <BasicCard
              noBorder
              elevation={0}
              ype
              title="Positive impact"
              titleColor="#2ecc71"
              fontSize="15px"
            >
              <MultipleTextFieldRHF
                name="positiveInfluence"
                title="positive impact"
                control={control}
              />
            </BasicCard>
            <BasicCard
              noBorder
              elevation={0}
              title="Negative impact"
              titleColor="#e74c3c"
              fontSize="15px"
            >
              <MultipleTextFieldRHF
                name="negativeInfluence"
                title="negative impact"
                control={control}
              />
            </BasicCard>
          </div>
        </BasicCard>
        <br />
        <div className="flex gap-5 justify-between">
          <BasicCard
            elevation={0}
            title="Opinion on presentation"
            titleBackground="#f5f5f5"
            titleColor="#001756"
            fontWeight={600}
            fontSize={17}
          >
            <RadioOptionsRHF
              title=""
              name="opinionOnValuation"
              control={control}
              options={opinionOv}
            />
          </BasicCard>
        </div>
        <br />
      </BasicCard>
    </div>
  );
};

export default FeedbackForm;
