import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { httpClientCustomer } from "../../utils/API/httpClient";

const initialState = {
  status: "idle",
  error: null,
  newsfeeds: [],
};

export const fetchNewsFeeds = createAsyncThunk(
  "newsfeeds/fetchNewsFeeds",
  async (dealValue, thunkAPI) => {
    try {
      const res = await httpClientCustomer.get(`/c/${dealValue}/newsfeeds`);
      return res.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message });
    }
  }
);

const newsfeedsSlice = createSlice({
  name: "newsfeeds",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchNewsFeeds.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchNewsFeeds.fulfilled, (state, { payload }) => {
      state.status = "success";
      state.newsfeeds = payload.data;
    });
    builder.addCase(fetchNewsFeeds.rejected, (state, { error }) => {
      state.status = "failed";
      state.error = error.message;
    });
  },
});

export default newsfeedsSlice.reducer;
