import { createSlice } from "@reduxjs/toolkit";
const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

const initialState = {
  anonymous: {},
};

const anonymousSlice = createSlice({
  name: "anonymous",
  initialState,
  reducers: {
    setAnonymousMapping(state, { payload }) {
      state.anonymous = {
        ...state?.anonymous,
        data: {
          ...state?.anonymous?.data,
          ...payload?.anonymousNames?.reduce(
            (acc, cur, idx) => ({
              ...acc,
              [cur]: `Investor ${alphabet[idx]}`,
            }),
            {},
          ),
        },
      };
    },
  },
});

export const { setAnonymousMapping } = anonymousSlice.actions;
export default anonymousSlice.reducer;
