import React from 'react';
import {useAuth0, withAuth0} from "@auth0/auth0-react";

function Login() {
  const {
    loginWithRedirect,
  } = useAuth0();

  return (
    <div className="w-full h-[100vh] flex justify-center items-center">
      <button
        className="bg-backgroundCard px-6 py-2 rounded-2xl text-title_bodyFonts
            shadow-md cursor-pointer"
        onClick={() => loginWithRedirect()}
      >
        Login
      </button>
    </div>
  );
}

export default withAuth0(Login);