import React from "react";
import ReactDOM from "react-dom/client";
import { Auth0Provider } from "@auth0/auth0-react";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { persistor, store } from "./redux/store";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import App from "./App";
import "./index.css";
import "@scalens/component-library/dist/index.css";
import { ToastContainer } from "react-toastify";

//React-toastify
import "react-toastify/dist/ReactToastify.css";

//Swiper style
import "swiper/css/bundle";

//React-date-range
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Auth0Provider
    domain={process.env.REACT_APP_TESTING_DOMAINE}
    clientId={process.env.REACT_APP_TESTING_CLIENT_ID}
    redirectUri={window.location.origin}
    audience={process.env.REACT_APP_TESTING_AUDIENCE}
    scope="read:current_user update:current_user_metadata"
    useRefreshTokens={true}
  >
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ToastContainer />
          <App />
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  </Auth0Provider>
);
