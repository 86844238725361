import * as React from "react";

// Components
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { IoFilterSharp } from "react-icons/io5";

const BasicModal = ({
  children,
  open,
  handleClose,
  handleSave,
  dialogTitle,
  dialogText,
  width = 600,
  isSave = false,
  titleColor = "#FFF",
  titleBackground = "#001756",
  filters,
  onClickFilters,
}) => {
  return (
    <Dialog
      sx={{
        "& .MuiPaper-root": {
          width,
          maxWidth: 900,
        },
        backdropFilter: "blur(3px)",
      }}
      open={open}
      maxWidth={false}
      onClose={handleClose}
    >
      <DialogTitle
        style={{
          backgroundColor: titleBackground,
          color: titleColor,
          fontWeight: "bold",
          fontSize: 17,
          padding: 10,
          marginBottom: "1rem",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {dialogTitle}
        {filters && (
          <div
            onClick={onClickFilters}
            className="flex items-center justify-end cursor-pointer text-[15px] "
          >
            <IoFilterSharp />
            <p className="text-white ml-1 ">Filters</p>
          </div>
        )}
      </DialogTitle>
      <DialogContent>
        {dialogText && (
          <DialogContentText id="alert-dialog-slide-description">
            {dialogText}
          </DialogContentText>
        )}
        {children}
      </DialogContent>

      {isSave ? (
        <div className="flex items-center justify-between py-3">
          <Button
            style={{ color: "#27ae60", fontWeight: "bold" }}
            onClick={handleClose}
          >
            <ArrowLeftIcon style={{ fontSize: 20, fontWeight: "bold" }} /> Back
          </Button>
          <Button
            style={{ color: "#001756", fontWeight: "bold" }}
            onClick={handleSave}
          >
            Save <ArrowRightIcon style={{ fontSize: 20, fontWeight: "bold" }} />
          </Button>
        </div>
      ) : (
        <div className="flex justify-end px-5 py-3">
          <Button
            style={{
              color: "#27ae60",
              fontWeight: "bold",
            }}
            onClick={handleClose}
          >
            <ArrowLeftIcon style={{ fontSize: 20, fontWeight: "bold" }} /> Back
          </Button>
        </div>
      )}
    </Dialog>
  );
};

export default BasicModal;
