import { useState, useEffect } from "react";
import { httpClientPublic } from "../utils/API/httpClient";

export function useFetchFieldKinds() {
  const [payload, isLoading, isError] = useFetchOnInit({
    url: "/forms/fields",
  });

  return [payload?.data || [], isLoading, isError];
}

function useFetchOnInit({ url }) {
  const [payload, setPayload] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    httpClientPublic
      .get(url)
      .then(({ data }) => {
        setPayload(data);
        setIsLoading(false);
        setIsError(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setIsError(true);
      });
  }, [url]);

  return [payload, isLoading, isError];
}
