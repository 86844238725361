import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { hotjar } from "react-hotjar";

// Components
import Layout from "./layouts/Layout";

// Utils
import {
  initializeAuth,
  initializeUserData,
  setApiToken,
  clearAuth,
} from "./redux/user/authSlice";
import { initializeAppData } from "./redux/app/appSlice";

function App() {
  const dispatch = useDispatch();
  const { isAuthenticated, user, getAccessTokenSilently, isLoading } = useAuth0();
  const userToken = useSelector((state) => state.auth.userToken);

  const initializeAuthentication = useCallback(async () => {
    try {
      if (!userToken) {
        const token = await getAccessTokenSilently();
        dispatch(setApiToken(token));
      }
    } catch (error) {
      console.error(error);
      dispatch(clearAuth());
    }
  }, [dispatch, getAccessTokenSilently, userToken]);

  const initializeApplication = useCallback(async () => {
    if (isAuthenticated && user) {
      await initializeAuthentication();
      await dispatch(initializeUserData(user)).unwrap();
      await dispatch(initializeAppData()).unwrap();
    }
  }, [isAuthenticated, user, initializeAuthentication, dispatch]);

  useEffect(() => {
    dispatch(initializeAuth());
  }, [dispatch]);

  useEffect(() => {
    if (!isLoading) {
      if (isAuthenticated) {
        initializeApplication();
      } else {
        dispatch(clearAuth());
      }
    }
  }, [isLoading, isAuthenticated, initializeApplication, dispatch]);

  useEffect(() => {
    hotjar.initialize(3845779, 6);
  }, []);

  return (
    <Router>
      <Layout />
    </Router>
  );
}

export default App;