import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { httpClientCustomer } from "../../utils/API/httpClient";

const initialState = {
  status: "idle",
  error: null,
  deals: [],
  currentDeal: {},
  dealInfos: {},
};

export const fetchDeals = createAsyncThunk(
  "deals/fetchDeals",
  async (thunkAPI) => {
    try {
      const res = await httpClientCustomer.get("/users/deals");
      return res.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message });
    }
  },
);

export const fetchDealInfos = createAsyncThunk(
  "deals/fetchDealInfos",
  async (thunkAPI, { getState }) => {
    const dealId = getState().deals.currentDeal.value;
    try {
      const {
        data: { data },
      } = await httpClientCustomer.get(`/c/${dealId}/deals/informations`);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message });
    }
  },
);

const dealsSlice = createSlice({
  name: "deals",
  initialState,
  reducers: {
    setCurrentDeal(state, { payload }) {
      state.currentDeal = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDeals.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchDeals.fulfilled, (state, { payload }) => {
      state.status = "success";
      state.deals = [...payload.data];
    });
    builder.addCase(fetchDeals.rejected, (state, { error }) => {
      state.status = "failed";
      state.error = error.message;
    });
    builder.addCase(fetchDealInfos.fulfilled, (state, { payload }) => {
      state.status = "success";
      state.dealInfos = payload;
    });
    builder.addCase(fetchDealInfos.rejected, (state, { error }) => {
      state.status = "failed";
      state.error = error.message;
    });
  },
});

export const { setCurrentDeal } = dealsSlice.actions;
export default dealsSlice.reducer;
