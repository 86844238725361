import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

// Components
import Select from "react-select";

// Utils
import * as R from "ramda";
import { fetchDealInfos, setCurrentDeal } from "../../redux/deals/dealsSlice";

const selectStyle = {
  control: (base, state) => ({
    ...base,
    cursor: "pointer",
    border: state.isFocused ? 0 : 0,
    boxShadow: state.isFocused ? 0 : 0,
    width: "max-content",
    "&:hover": {
      border: state.isFocused ? 0 : 0,
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#4B7AA6" : "#FFF",
    borderRadius: 5,
    color: state.isSelected ? "#FFF" : "#757575",
    cursor: "pointer",
    fontSize: 14,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: state.isSelected ? "#4B7AA6" : "#F1F1F8",
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#71C1CA",
    fontSize: 14,
    fontWeight: "bold",
    textTransform: "capitalize",
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 10,
    minWidth: "100%",
    width: "max-content",
  }),
};

const CustomersButton = () => {
  const deals = useSelector((state) => state.deals.deals);
  const userId = useSelector((state) => state.auth.userId);
  const currentDeal = useSelector((state) => state.deals.currentDeal);
  const dispatch = useDispatch();

  const currentCustomer = useMemo(() => {
    return {
      label: currentDeal.label,
      value: currentDeal.value,
    };
  }, [currentDeal]);

  const options = useMemo(
    () =>
      R.pipe(
        R.reject((d) => d.id === userId),
        R.map(({ company_name, id }) => ({
          label: company_name,
          value: id,
        })),
      )(deals),
    [userId, deals],
  );

  const handleClickEvent = useCallback(
    (option) => {
      dispatch(setCurrentDeal(option));
      dispatch(fetchDealInfos(option.value));
    },
    [dispatch],
  );

  return (
    deals && (
      <Select
        value={currentCustomer.label ? currentCustomer : null}
        placeholder="Company"
        options={options}
        styles={selectStyle}
        onChange={handleClickEvent}
      />
    )
  );
};

export default CustomersButton;
