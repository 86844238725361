import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

// Components
import Nav from "../../components/Nav/Nav";

function Header() {
  const { isAuthenticated } = useAuth0();

  return isAuthenticated && <Nav />;
}

export default Header;
