const initialState = {
  monitoring: [],
  lastFeedbacks: [],
};

const dashboardReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case "WEEKLY_MONITORING":
      return {
        ...state,
        monitoring: action.monitoring,
      };
    case "LAST_FEEDBACKS":
      return {
        ...state,
        lastFeedbacks: action.lastFeedbacks,
      };
    case "NEWS_FEEDS":
      return {
        ...state,
        newsFeeds: action.newsFeeds,
      };

    default:
      return state;
  }
};

export default dashboardReducer;
