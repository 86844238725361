import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchDeals } from "../deals/dealsSlice";
import { getAlerts } from "../alerts/alertsSlice";
import { fetchPeers } from "../peers/peersSlice";
import { fetchResearchpoolToken } from "../brokerNotes/brokerNotesSlice";

export const initializeAppData = createAsyncThunk(
  "app/initializeAppData",
  async (_, { dispatch }) => {
    await Promise.all([
      dispatch(fetchDeals()),
      dispatch(getAlerts()),
      dispatch(fetchPeers()),
      dispatch(fetchResearchpoolToken()),
    ]);
  },
);

const appSlice = createSlice({
  name: "app",
  initialState: {
    isInitialized: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(initializeAppData.fulfilled, (state) => {
      state.isInitialized = true;
    });
  },
});

export default appSlice.reducer;
