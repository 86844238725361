// Components
import { Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";

const { Dragger } = Upload;

const DocumentUploader = ({
  allowedTypes,
  beforeUpload,
  defaultFileList,
  fileList,
  handleFileChange,
  handleFileRemove,
}) => {

  return (
    <Dragger
      listType="text"
      defaultFileList={defaultFileList}
      fileList={fileList}
      multiple={true}
      accept={allowedTypes}
      beforeUpload={beforeUpload}
      onChange={handleFileChange}
      onRemove={handleFileRemove}
    >
      <div className="p-3">
        <p className="ant-upload-drag-icon">
          <InboxOutlined style={{ color: "#001756" }} />
        </p>
        <p className="ant-upload-text">
          Click or drag files to this area to upload
        </p>
      </div>
    </Dragger>
  );
};

export default DocumentUploader;
