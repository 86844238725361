// Components
import {
  toastErrorHelper,
  toastSuccessHelper,
} from "../../components/Toasts/Toasts";

// Utils
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { httpClientInvestigate } from "../../utils/API/httpClient";

const initialState = {
  status: "idle",
  error: null,
  data: {
    "first_name": "",
    "last_name": "",
    company: "",
    email: "",
    "phone_number": "",
  }
};

export const fetchProfile = createAsyncThunk(
  "profile/fetchProfile",
  async (thunkAPI, { getState }) => {
    const userId = getState().auth.investigateUserId;
    try {
      const response = await httpClientInvestigate.get(`/users/${userId}`);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message });
    }
  },
);

export const updateProfile = createAsyncThunk(
  "profile/updateProfile",
  async (thunkAPI, { getState }) => {
    const userId = getState().auth.investigateUserId;
    const profile = getState().profile.data;
    try {
      const payload = {
        "first_name": profile["first_name"],
        "last_name": profile["last_name"],
        company: profile["company"],
        email: profile["email"],
        "phone_number": profile["phone_number"],
      };
      await httpClientInvestigate.patch(`/users/${userId}`, payload);
      toastSuccessHelper("Profile updated successfully");
    } catch (error) {
      toastErrorHelper("Error while updating profile");
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setUserProfile(state, { payload }) {
      state.data = payload
    },
  },
  extraReducers: (builder) => {
    //------------For Profile Fetching----------------
    builder.addCase(fetchProfile.fulfilled, (state, { payload }) => {
      state.data = payload;
    });
    //------------For Profile Updating----------------
    builder.addCase(updateProfile.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(updateProfile.fulfilled, (state) => {
      state.error = "null";
      state.status = "success";
    });
    builder.addCase(updateProfile.rejected, (state, { error }) => {
      state.status = "failed";
      state.error = error.message;
    });
  },
});

export const { setUserProfile } = profileSlice.actions;
export default profileSlice.reducer;
