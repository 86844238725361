// Components
import {
  toastErrorHelper,
  toastSuccessHelper,
} from "../../components/Toasts/Toasts";

// Utils
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { httpClientInvestigate } from "../../utils/API/httpClient";

const initialState = {
  status: "idle",
  error: null,
  peersSelected: [],
};

export const fetchPeers = createAsyncThunk(
  "peers/fetchPeers",
  async (thunkAPI, { getState }) => {
    const userId = getState().auth.investigateUserId;
    try {
      const resp = await httpClientInvestigate.get(`/users/${userId}/peers`);
      return resp.data.map((peer) => ({
        id: peer.id,
        code: peer.code,
        country: peer.country,
        label: peer.name,
        value: peer.isin,
      }));
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const updatePeers = createAsyncThunk(
  "peers/updatePeers",
  async (thunkAPI, { getState }) => {
    const userId = getState().auth.investigateUserId;
    const peersSelected = getState().peers.peersSelected.map((peer) => peer.id);
    try {
      await httpClientInvestigate.post(
        `/users/${userId}/update-peers`,
        peersSelected,
      );
      toastSuccessHelper("Peers updated successfully");
    } catch (error) {
      toastErrorHelper("Error while updating peers");
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const peersSlice = createSlice({
  name: "peers",
  initialState,
  reducers: {
    setPeersSelected(state, { payload }) {
      state.peersSelected = payload;
    },
  },
  extraReducers: (builder) => {
    //------------For Peers Fetching----------------
    builder.addCase(fetchPeers.fulfilled, (state, { payload }) => {
      state.peersSelected = payload;
    });
    //------------For Peers Updating----------------
    builder.addCase(updatePeers.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(updatePeers.fulfilled, (state) => {
      state.error = "null";
      state.status = "success";
    });
    builder.addCase(updatePeers.rejected, (state, { error }) => {
      state.status = "failed";
      state.error = error.message;
    });
  },
});

export const { setPeersSelected } = peersSlice.actions;
export default peersSlice.reducer;
