import axios from "axios";

// Utils
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { httpClientInvestigatePublic } from "../../utils/API/httpClient";

//---------------------------------------Initial State----------------------------------------
const initialState = {
  //---Requests Statuses-------
  //--Public--
  status: "idle",
  //--Private--
  researchpoolToken: "",
  brokerNotesSuccess: false,
  companyImageSourceSuccess: false,
  //---Results-------
  brokerNotes: [],
  companyImageSource: [],
  hasMore: true,
  error: null,
  //---Additional Attributes-------
  nextPage: 1, // Keep track of next page to be fetched
};
//---------------------------------------Async Thunks----------------------------------------
export const fetchResearchpoolToken = createAsyncThunk(
  "broker/researchpoolToken",
  async (thunkAPI) => {
    try {
      const result = await axios.post("https://api.researchpool.com/login", {
        username: process.env.REACT_APP_RESEARCHPOOL_USERNAME,
        password: process.env.REACT_APP_RESEARCHPOOL_PASSWORD,
      });
      return { researchpoolToken: result.data.token };
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message });
    }
  }
);
export const fetchBrokerNotes = createAsyncThunk(
  "broker/fetchBorkerNotes",
  async ({ isins }, thunkAPI) => {
    const state = thunkAPI.getState().brokerNotes;
    let fetchedReports = [];
    let filteredBrokerNotes = [];
    let { nextPage, researchpoolToken } = state;

    const ITEMS_PER_PAGE = 4;
    try {
      while (filteredBrokerNotes.length < ITEMS_PER_PAGE) {
        const res = await axios.get("https://api.researchpool.com/products", {
          params: {
            "issuers.isin[]": isins, // Assume isins is also part of state
            page: nextPage,
          },
          headers: {
            Accept: "application/ld+json",
            "Content-Type": "application/ld+json",
            Authorization: `Bearer ${researchpoolToken}`,
          },
        });
        fetchedReports = res.data["hydra:member"][0];

        const filteredFetchedReports = fetchedReports.filter(
          (report) =>
            report.category !== "/categories/31" &&
            report.language === "English"
        );

        filteredBrokerNotes = [
          ...filteredBrokerNotes,
          ...filteredFetchedReports,
        ];

        if (fetchedReports.length === 0) {
          break;
        }
        nextPage++;
      }
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message });
    }
    return {
      data: filteredBrokerNotes,
      nextPage,
      hasMore: fetchedReports.length !== 0,
    };
  }
);

export const fetchCompanyImageSource = createAsyncThunk(
  "broker/fetchCompanyImageSource",
  async (tickers, thunkAPI) => {
    try {
      const res = await httpClientInvestigatePublic.get(
        "/companies/logos",
        {
          params: { tickers: JSON.stringify(tickers) },
        }
      );
      return res.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message });
    }
  }
);
//---------------------------------------Slice----------------------------------------
const brokerNotesSlice = createSlice({
  name: "brokerNote",
  initialState,
  reducers: {
    resetBrokerNotes: (state, action) => {
      state.brokerNotes = [];
      state.hasMore = true;
      state.companyImageSource = [];
      state.status = "idle";
      state.error = null;
      state.brokerNotesSuccess = false;
      state.companyImageSourceSuccess = false;
      state.nextPage = 1;
    },
  },
  extraReducers: (builder) => {
    //-----------------------For researchpoolToken--------------------------
    builder.addCase(fetchResearchpoolToken.fulfilled, (state, { payload }) => {
      state.researchpoolToken = payload.researchpoolToken;
    });
    //-----------------------For fetchBrokerNotes---------------------------
    builder.addCase(fetchBrokerNotes.pending, (state) => {
      state.brokerNotesSuccess = false;
      state.status = "pending";
    });
    builder.addCase(fetchBrokerNotes.fulfilled, (state, { payload }) => {
      state.brokerNotesSuccess = true;
      if (state.brokerNotesSuccess && state.companyImageSourceSuccess) {
        state.status = "success";
      }
      state.brokerNotes = state.brokerNotes.concat(payload.data);
      state.nextPage = payload.nextPage;
      state.hasMore = payload.hasMore;
    });
    builder.addCase(fetchBrokerNotes.rejected, (state, { error }) => {
      state.brokerNotesSuccess = false;
      state.status = "failed";
      state.error = error.message;
    });
    //-----------------------For fetchCompanyImageSource---------------------------
    builder.addCase(fetchCompanyImageSource.pending, (state) => {
      state.status = "pending";
      state.companyImageSourceSuccess = false;
    });
    builder.addCase(fetchCompanyImageSource.fulfilled, (state, { payload }) => {
      state.companyImageSourceSuccess = true;
      if (state.brokerNotesSuccess && state.companyImageSourceSuccess) {
        state.status = "success";
      }
      state.companyImageSource = payload;
    });
    builder.addCase(fetchCompanyImageSource.rejected, (state, { error }) => {
      state.companyImageSourceSuccess = false;
      state.status = "failed";
      state.error = error.message;
    });
  },
});

export default brokerNotesSlice.reducer;
export const { resetBrokerNotes } = brokerNotesSlice.actions;
