import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

import dashboardReducer from "./reducers/dashboardReducer";
import monitorReducer from "./reducers/MonitorReducer";

import anonymousSlice from "./dataviz/anonymousSlice";
import alertsSlice from "./alerts/alertsSlice";
import authSlice from "./user/authSlice";
import brokerNotesSlice from "./brokerNotes/brokerNotesSlice";
import calendarSlice from "./calendar/calendarSlice";
import dealsSlice from "./deals/dealsSlice";
import fetchPeers from "./peers/peersSlice";
import newsfeedsSlice from "./newsfeeds/newsfeedsSlice";
import newsSlice from "./news/newsSlice";
import profileSlice from "./profile/profileSlice";
import tabsSlice from "./navigation/tabsSlice";
import tierSlice from "./dataviz/tierSlice";

const persistConfig = {
  key: "scalens",
  storage,
};

const rootReducer = combineReducers({
  alerts: alertsSlice,
  allNewsFeeds: newsfeedsSlice,
  anonymous: anonymousSlice,
  auth: authSlice,
  brokerNotes: brokerNotesSlice,
  calendar: calendarSlice,
  monitorContent: monitorReducer,
  deals: dealsSlice,
  lastFeedbacks: dashboardReducer,
  news: newsSlice,
  peers: fetchPeers,
  profile: profileSlice,
  tabs: tabsSlice,
  tier: tierSlice,
  weekMonitoring: dashboardReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);
