import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { httpClientInvestigate } from "../../utils/API/httpClient";

const initialState = {
  status: "idle",
  error: null,
  news: [],
  page: 1,
};

export const fetchNews = createAsyncThunk(
  "news/fetchNews",
  async (thunkAPI, { getState }) => {
    const page = getState().news.page || 1;
    // const peersNames = getState().peers.peersSelected.map((peer) => peer.label);
    try {
      const res = await httpClientInvestigate.get(
        `/dashboard/news?page=${page}&pagesize=15`,
        {
          params: {
            companies: JSON.stringify([]),
          },
        },
      );
      return res.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message });
    }
  },
);

const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {
    resetNews(state) {
      state.news = [];
      state.page = 1;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNews.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchNews.fulfilled, (state, { payload }) => {
      state.status = "success";
      state.news = [...state.news, ...payload];
      state.page += 1; 
    });
    builder.addCase(fetchNews.rejected, (state, { error }) => {
      state.status = "failed";
      state.error = error.message;
    });
  },
});

export const { resetNews } = newsSlice.actions;
export default newsSlice.reducer;
