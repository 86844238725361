import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAuth0, withAuth0 } from "@auth0/auth0-react";

// Components
import CustomersButton from "../../../components/Nav/CustomersButton";
import UploadFeedback from "../../../pages/Dashboard/MainDashboardContent/Components/UploadFeedback/UploadFeedback";
import FullDialog from "../../../components/FullDialog/FullDialog";
import Login from "../../../pages/Login/Login";
// import NotifMenu from "../../../components/Nav/NotifMenu";
import { DropdownMenu } from "@scalens/component-library";

// Utils
import useBreakpoint from "use-breakpoint";

const BREAKPOINTS = { sm: 735, md: 1160, lg: 1350 };

function HeaderRight({
  notifications,
  setMainTab,
}) {
  const navigate = useNavigate();
  const { breakpoint } = useBreakpoint(BREAKPOINTS);

  const [openUploadModal, setOpenUploadModal] = useState(null);
  const profile = useSelector((state) => state.profile.data);

  const { isAuthenticated, error, user, logout } = useAuth0();
  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  const handleSelectOption = (value) => {
    if (value === "logout") {
      logout({ returnTo: window.location.origin });
      localStorage.removeItem("user_token");
    } else {
      setMainTab("/");
      navigate(`/${value}`);
    }
  };

  return (
    <>
      <div className="absolute md:right-0 md:top-4">
        {isAuthenticated ? (
          <>
            <FullDialog
              open={openUploadModal === "call-report"}
              handleClose={() => setOpenUploadModal(null)}
              onClickSend={() => setOpenUploadModal(null)}
            />
            <UploadFeedback
              open={openUploadModal === "feedback-report"}
              handleClose={() => setOpenUploadModal(null)}
            />
            <div className="flex items-center">
              <DropdownMenu
                collapsed={breakpoint !== "md" && breakpoint !== "lg"}
                icon="upload"
                label="Feedback"
                onSelect={setOpenUploadModal}
                options={[
                  [
                    {
                      label: "Meeting Minutes",
                      value: "call-report",
                    },
                    {
                      label: "Upload Feedback",
                      value: "feedback-report",
                    },
                  ],
                ]}
              />
              <CustomersButton />
              {/* TODO: reactivate later */}
              {/* <NotifMenu notifications={notifications} /> */}
              <DropdownMenu
                avatar={{
                  size: "28px",
                  text:
                    (profile.first_name[0] || "") +
                    (profile.last_name[0] || "") || "?",
                }}
                collapsed={breakpoint !== "lg"}
                label={user.email}
                // on select, route to corresponding page
                onSelect={value => handleSelectOption(value)}
                options={[
                  [
                    {
                      label: "Profile",
                      icon: "user",
                      value: "profile",
                    },
                    {
                      label: "Settings",
                      icon: "settings",
                      value: "settings",
                    },
                    {
                      label: "Contact",
                      icon: "mail",
                      value: "contact",
                    },
                  ],
                  [
                    {
                      label: "Logout",
                      icon: "log-out",
                      value: "logout",
                    },
                  ]
                ]}
              />
            </div>
          </>
        ) : (
          <Login />
        )}
      </div>
    </>
  );
}

export default withAuth0(HeaderRight);
