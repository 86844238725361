import React from "react";

// Components
import Slider from "@mui/material/Slider";

const Rating = ({ color = "#0d47a1", value, onChange, isDisabled = false }) => {
  return (
    <Slider
      disabled={isDisabled}
      sx={{
        width: "100%",
        margin: "60px auto 0 auto",

        "& .MuiSlider-valueLabel": {
          backgroundColor: color,
          color: "#045089",
          padding: "5px 10px 10px 10px",
        },
        "& .MuiSlider-thumb": {
          color,
          border: "1px solid transparent",
          height: 15,
          width: 15,
        },
        "& .MuiSlider-markLabel": {
          color: "#747C94",
        },
        "& .MuiSlider-valueLabelLabel": {
          fontSize: 12,
          fontWeight: "bold",
          color: "#FFF",
          borderRadius: "40%",
        },
        "& .MuiSlider-track": {
          color,
        },
      }}
      max={10}
      min={-1}
      scale={(x) => {
        if (x === -1) return "n/a";
        return x;
      }}
      step={1}
      value={value}
      valueLabelDisplay="on"
      onChange={onChange}
    />
  );
};

export default Rating;
