import React, { lazy } from "react";
import { Route, Routes } from "react-router-dom";
// Components
import Header from "./header/Header";
// Utils
import SuspenseWrapper from "../utils/SuspenseWrapper/SuspenseWrapper";

const Dashboard = lazy(() => import("../pages/Dashboard/Dashboard"));
const UserProfile = lazy(() => import("../pages/UserProfile/UserProfile"));
const UserSettings = lazy(() => import("../pages/UserSettings/UserSettings"));
const ContactUs = lazy(() => import("../pages/ContactUs/ContactUs"));
const ScalensIR = lazy(() => import("../pages/ScalensIR/ScalensIR"));
const Monitor = lazy(() => import("../pages/Monitor/Monitor"));
const MonitorCardViewer = lazy(() =>
  import("../pages/Monitor/MonitorCardViewer"),
);
const CRM = lazy(() => import("../pages/CRM/MainCRM"));
const OAuthCallback = lazy(() => import("../pages/OAuthCallback"));

function Layout() {
  return (
    <>
      <Header />
      <div className="container mt-[5.5rem] relative m-auto">
        <Routes>
          <Route
            exact
            path="/"
            element={<SuspenseWrapper LazyComponent={Dashboard} />}
          />
          <Route
            path="/profile"
            element={<SuspenseWrapper LazyComponent={UserProfile} />}
          />
          <Route
            path="/settings"
            element={<SuspenseWrapper LazyComponent={UserSettings} />}
          />
          <Route
            path="/contact"
            element={<SuspenseWrapper LazyComponent={ContactUs} />}
          />
          <Route
            path="/scalens-ir"
            element={<SuspenseWrapper LazyComponent={ScalensIR} />}
          />
          <Route
            path="/monitor"
            element={<SuspenseWrapper LazyComponent={Monitor} />}
          />
          <Route
            path="/monitor/card/:slug"
            element={<SuspenseWrapper LazyComponent={MonitorCardViewer} />}
          />
          <Route
            path="/crm"
            element={<SuspenseWrapper LazyComponent={CRM} />}
          />
          <Route
            path="/google_gmail/confirm"
            element={<SuspenseWrapper LazyComponent={OAuthCallback} />}
          />
          <Route
            path="/microsoft_outlook/confirm"
            element={<SuspenseWrapper LazyComponent={OAuthCallback} />}
          />
        </Routes>
      </div>
    </>
  );
}

export default Layout;
