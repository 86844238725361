import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dashboardTab: 0,
  scalensIRTab: 0,
  crmTab: 0,
};

const tabsSlice = createSlice({
  name: "tabs",
  initialState,
  reducers: {
    setHomeTab(state, { payload }) {
      state.dashboardTab = payload;
    },
    setScalensIRTab(state, { payload }) {
      state.scalensIRTab = payload;
    },
    setCRMTab(state, { payload }) {
      state.crmTab = payload;
    },
  },
});

export const {
  setHomeTab,
  setScalensIRTab,
  setCRMTab,
} = tabsSlice.actions;
export default tabsSlice.reducer;
