import React from "react";

// Components
import { IconButton, Typography } from "@mui/material";
import BasicCard from "../Card/BasicCard";
import CloseIcon from "@mui/icons-material/Close";

import {
  DateFieldRHF,
  NumberFieldRHF,
  SelectFieldRHF,
  TextFieldRHF,
  YesNoFieldRHF,
} from "./FieldComponents";

// Utils
import "react-datepicker/dist/react-datepicker.css";
import { activityTypes } from "../../utils/helpers";
import { useFetchInvestors } from "./hooks";

const ActivityReportForm = ({ control, errors, handleClose }) => {
  const [investors, isInvestorsLoading, isInvestorsError] = useFetchInvestors();

  return (
    <div className="mt-[1em]">
      <div className="flex items-center justify-between">
        <Typography
          sx={{
            flex: 1,
            color: "#001756",
            fontWeight: "bold",
          }}
          variant="h5"
          component="div"
        >
          Meeting Minutes 
        </Typography>
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </div>

      <br />
      <BasicCard noBorder elevation={0} padding="1rem">
        {!isInvestorsLoading && !isInvestorsError && (
          <div className="mt-[0.5em] ">
            <SelectFieldRHF
              isRequired
              title="Investor name"
              name="investor"
              control={control}
              options={investors}
            />
          </div>
        )}
        <div className="my-[1rem] flex flex-wrap justify-between">
          <TextFieldRHF
            title="First name"
            name="firstName"
            control={control}
            errors={errors}
          />
          <TextFieldRHF
            title="Last name"
            name="lastName"
            control={control}
            errors={errors}
          />
          <TextFieldRHF
            title="Email"
            name="email"
            control={control}
            errors={errors}
          />
        </div>
        <SelectFieldRHF
          isRequired
          title="Activity type"
          name="activityType"
          control={control}
          options={activityTypes}
        />
        <br />
        <DateFieldRHF
          isRequired
          title="Activity date"
          name="activityDate"
          control={control}
          errors={errors}
        />

        <br />

        <div className="flex items-baseline justify-between gap-5">
          <YesNoFieldRHF
            name="isInvested"
            title="Existing shareholder of the company?"
            control={control}
          />
          <NumberFieldRHF
            isRequired
            title="Number of shares owned?"
            name="nbShares"
            control={control}
            errors={errors}
          />
        </div>
        <br />

        <YesNoFieldRHF
          name="isWillingToBuy"
          title="Open to buy/increase shares of the company?"
          control={control}
        />
        <br />
      </BasicCard>
    </div>
  );
};

export default ActivityReportForm;
