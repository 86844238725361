import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

// Components
import { VscClose } from "react-icons/vsc";
import { HiMenuAlt1 } from "react-icons/hi";
import HeaderRight from "../../layouts/header/HeaderRight/HeaderRight";
import { Tabs } from "@scalens/component-library";

// Utils
import { httpClientCustomer } from "../../utils/API/httpClient";

const LOGO_SCALENS = "../../images/logo/scalens_logo_2025.png";

const NAV_LINKS = [
  {
    // formerly My Dashboard
    label: "**Home**",
    icon: "home",
    color: "#48A1DC",
    value: "/",
  },
  {
    // formerly Data Hub
    label: "**Monitor**",
    icon: "bar-chart-2",
    color: "#3C5AE2",
    value: "/monitor",
  },
  {
    // formerly Intelligence
    label: "**Scalens IR**",
    icon: "zap",
    color: "#3BA2BE",
    value: "/scalens-ir",
  },
  {
    label: "**CRM**",
    badge: { color: "#955FDB", label: "New!" },
    icon: "users",
    color: "#3BA2BE",
    value: "/crm",
  },
  {
    label: "**BoardData**",
    icon: "database",
    color: "#233A7C",
    value: "https://boarddata.io/",
    target: "_blank",
  },
];

const Nav = () => {
  // Main Navigation (Tabs)
  const location = useLocation();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(location.pathname);
  const [open, setOpen] = useState(false);
  const [notifications, setNotifications] = useState(null);

  const handleChangeTab = (path) => {
    if (path.startsWith("https://")) {
      window.open(path, "_blank");
    } else {
      setActiveTab(path);
      navigate(path);
    }
    setOpen(false)
  };

  // Notifications
  const dealId = useSelector((state) => state.deals.currentDeal.value);
  const userToken = useSelector((state) => state.auth.userToken);

  const fetchNotifications = async () => {
    try {
      const {
        data: { data },
      } = await httpClientCustomer.get(`c/${dealId}/notifications`);
      setNotifications(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (dealId && userToken) fetchNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealId, userToken]);

  return (
    <div className="shadow-sm w-full bg-white fixed top-0 left-0 z-[999]">
      <div className="md:flex py-2">
        <img
          onClick={() => navigate("/")}
          src={LOGO_SCALENS}
          alt="logo scalens"
          className="max-w-[200px] max-h-[54px] ml-2 md:ml-4 2xl:mr-[30px] md:mr-[2%] cursor-pointer"
        />
        <div
          className="text-3xl text-[#001756] absolute right-8 top-6 cursor-pointer md:hidden"
          onClick={() => setOpen((prev) => !prev)}
        >
          {open ? <VscClose /> : <HiMenuAlt1 />}
        </div>

        <div
          className={`bg-white md:flex md:items-center md:justify-between md:pb-0 pb-[3.5rem] absolute md:static md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9  ${open ? "top-[4.5rem]" : "top-[-490px]"
            }`}
        >
          <Tabs
            ariaLabel="main navigation tabs"
            options={NAV_LINKS}
            onChange={(path) => handleChangeTab(path)}
            selectedTab={activeTab}
            size="13px"
          />
          <HeaderRight
            notifications={notifications}
            setMainTab={(value) => setActiveTab(value)}
          />
        </div>
      </div>
    </div>
  );
};

export default Nav;
