import { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

// Utils
import {
  httpClientCustomer,
  httpClientGlobal,
  httpClientPublic,
} from "../../utils/API/httpClient";

export function useFetchOpinionOv() {
  const [payload, isLoading, isError] = useFetchOnInit({
    url: "/feedbacks/opiniononvaluation",
    client: httpClientPublic,
  });

  const formattedPayload = useMemo(
    () =>
      payload?.data?.map(({ name, id }) => ({
        label: name,
        value: `${id}`,
      })) || [],
    [payload],
  );

  return [formattedPayload, isLoading, isError];
}

export function useFetchEvents() {
  const currentDeal = useSelector((state) => state.deals.currentDeal);
  const [payload, isLoading, isError] = useFetchOnInit({
    url: `/c/${currentDeal.value}/events`,
    client: httpClientCustomer,
  });

  const formattedPayload = useMemo(
    () =>
      payload?.data?.map(({ id, name }) => ({
        label: name,
        value: id,
      })) || [],
    [payload],
  );

  return [formattedPayload, isLoading, isError];
}

export function useFetchValuationMethodologies() {
  const [payload, isLoading, isError] = useFetchOnInit({
    url: "/feedbacks/methodologies",
    client: httpClientPublic,
  });
  const formattedPayload = useMemo(
    () =>
      payload?.data?.map(({ name, id }) => ({
        label: name,
        value: name,
        id,
      })) || [],
    [payload],
  );

  return [formattedPayload, isLoading, isError];
}

export function useFetchInvestors() {
  const [payload, isLoading, isError] = useFetchOnInit({
    url: "/companies?flag=investor",
    client: httpClientGlobal,
  });

  const formattedPayload = useMemo(
    () =>
      payload?.data?.map(({ id, name }) => ({
        label: name,
        value: id,
      })) || [],
    [payload],
  );

  return [formattedPayload, isLoading, isError];
}

function useFetchOnInit({ url, client }) {
  const [payload, setPayload] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    client
      .get(url)
      .then(({ data }) => {
        setPayload(data);
        setIsLoading(false);
        setIsError(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setIsError(true);
      });
  }, [url, client]);

  return [payload, isLoading, isError];
}
