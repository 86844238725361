// Components
import {
  toastErrorHelper,
  toastSuccessHelper,
} from "../../components/Toasts/Toasts";

// Utils
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { httpClientInvestigate } from "../../utils/API/httpClient";

const initialState = {
  status: "idle",
  error: null,
  agendaPreferences: {
    notif_type: "AGENDA",
    notif_frequency: "IMMEDIATLY",
    notif_method: "EMAIL",
    notif_filters: {
      INDEX: [],
      COUNTRY: [],
      COMPANY: [],
      INDUSTRY: [],
    },
  },
  reportPreferences: {
    notif_type: "REPORT",
    notif_frequency: "IMMEDIATLY",
    notif_method: "EMAIL",
    notif_filters: {
      INDEX: [],
      COUNTRY: [],
      COMPANY: [],
      INDUSTRY: [],
    },
  },
  h1Preferences: {
    notif_type: "H1_RESULT",
    notif_frequency: "IMMEDIATLY",
    notif_method: "EMAIL",
    notif_filters: {
      INDEX: [],
      COUNTRY: [],
      COMPANY: [],
      INDUSTRY: [],
    },
  },
  fyPreferences: {
    notif_type: "FY_RESULT",
    notif_frequency: "IMMEDIATLY",
    notif_method: "EMAIL",
    notif_filters: {
      INDEX: [],
      COUNTRY: [],
      COMPANY: [],
      INDUSTRY: [],
    },
  },
};

export const updateAlerts = createAsyncThunk(
  "alerts/updateAlerts",
  async (thunkAPI, { getState }) => {
    const userId = getState().auth.investigateUserId;
    const agendaPreferences = getState().alerts.agendaPreferences;
    const reportPreferences = getState().alerts.reportPreferences;
    const h1Preferences = getState().alerts.h1Preferences;
    const fyPreferences = getState().alerts.fyPreferences;
    try {
      const payload = [
        agendaPreferences,
        reportPreferences,
        h1Preferences,
        fyPreferences,
      ];
      await httpClientInvestigate.post(
        `/users/${userId}/notifications`,
        payload,
      );
      toastSuccessHelper("Alerts updated successfully");
    } catch (error) {
      toastErrorHelper("Error while updating alerts");
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const getAlerts = createAsyncThunk(
  "alerts/getAlerts",
  async (thunkAPI, { getState }) => {
    const userId = getState().auth.investigateUserId;
    try {
      const { data } = await httpClientInvestigate.get(
        `/users/${userId}/notifications`,
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const alertsSlice = createSlice({
  name: "alerts",
  initialState,
  reducers: {
    setEmailNotifications(state, { payload }) {
      const value = payload.value === true ? "EMAIL" : "NONE";
      switch (payload.name) {
        case "Agenda":
          state.agendaPreferences.notif_method = value;
          break;
        case "AGM Minutes":
          state.reportPreferences.notif_method = value;
          break;
        case "H1 Results":
          state.h1Preferences.notif_method = value;
          break;
        case "FY Results":
          state.fyPreferences.notif_method = value;
          break;
        default:
          return null;
      }
    },
    setAgendaPreferences(state, { payload }) {
      state.agendaPreferences = {
        ...state.agendaPreferences,
        notif_filters: {
          ...state.agendaPreferences?.notif_filters,
          [payload.option]: payload.data,
        },
      };
    },
    setReportPreferences(state, { payload }) {
      state.reportPreferences = {
        ...state.reportPreferences,
        notif_filters: {
          ...state.reportPreferences?.notif_filters,
          [payload.option]: payload.data,
        },
      };
    },
    setH1Preferences(state, { payload }) {
      state.h1Preferences = {
        ...state.h1Preferences,
        notif_filters: {
          ...state.h1Preferences?.notif_filters,
          [payload.option]: payload.data,
        },
      };
    },
    setFyPreferences(state, { payload }) {
      state.fyPreferences = {
        ...state.fyPreferences,
        notif_filters: {
          ...state.fyPreferences?.notif_filters,
          [payload.option]: payload.data,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateAlerts.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(updateAlerts.fulfilled, (state) => {
      state.error = "null";
      state.status = "success";
    });
    builder.addCase(updateAlerts.rejected, (state, { error }) => {
      state.status = "failed";
      state.error = error.message;
    });
    builder.addCase(getAlerts.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(getAlerts.fulfilled, (state, { payload }) => {
      state.error = "null";
      state.status = "success";
      state.agendaPreferences =
        payload.find((p) => p.notif_type === "AGENDA") ||
        initialState.agendaPreferences;
      state.reportPreferences =
        payload.find((p) => p.notif_type === "REPORT") ||
        initialState.reportPreferences;
      state.h1Preferences =
        payload.find((p) => p.notif_type === "H1_RESULT") ||
        initialState.h1Preferences;
      state.fyPreferences =
        payload.find((p) => p.notif_type === "FY_RESULT") ||
        initialState.fyPreferences;
    });
    builder.addCase(getAlerts.rejected, (state, { error }) => {
      state.status = "failed";
      state.error = error.message;
    });
  },
});

export const {
  setEmailNotifications,
  setAgendaPreferences,
  setReportPreferences,
  setH1Preferences,
  setFyPreferences,
} = alertsSlice.actions;
export default alertsSlice.reducer;
