import axios from "axios";

// Utils
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { formatEventsPayload, sortedDateAsc } from "../../utils/helpers";
import dayjs from "dayjs";

const API_URL = process.env.REACT_APP_API_URL;

const initialState = {
  status: "idle",
  error: null,
  companies: [],
  countries: [],
  markets: [],
  selectedCompanies: [],
  selectedCountries: [],
  selectedMarkets: [],
  eventType: "ALL",
  events: [],
  allEvents: [],
};

export const fetchCompanies = createAsyncThunk(
  "calendar/fetchCompanies",
  async (thunkAPI) => {
    try {
      const res = await axios.get(`${API_URL}/companies`);
      return res.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message });
    }
  },
);

export const fetchCountries = createAsyncThunk(
  "calendar/fetchCountries",
  async (thunkAPI) => {
    try {
      const res = await axios.get(`${API_URL}/countries`);
      return res.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message });
    }
  },
);

export const fetchMarkets = createAsyncThunk(
  "calendar/fetchMarkets",
  async (thunkAPI) => {
    try {
      const res = await axios.get(`${API_URL}/indices`);
      return res.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message });
    }
  },
);

export const fetchEvents = createAsyncThunk(
  "calendar/fetchEvents",
  async (thunkAPI, { getState }) => {
    const companiesIds = getState().calendar.selectedCompanies.map((c) => c.id);
    const countries = getState().calendar.selectedCountries;
    const markets = getState().calendar.selectedMarkets.map((c) => c.label);
    const payload = {
      event_type: getState().calendar.eventType,
      company_ids: JSON.stringify(companiesIds),
      countries: JSON.stringify(countries),
      market_indices: JSON.stringify(markets),
      from_date: dayjs().subtract(1, "year").format("YYYY-MM-DD"),
      to_date: dayjs().add(1, "year").format("YYYY-MM-DD"),
    };
    try {
      const { data } = await axios.get(`${API_URL}/dashboard/calendar`, {
        params: payload,
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message });
    }
  },
);

export const fetchAllEvents = createAsyncThunk(
  "calendar/fetchAllEvents",
  async (thunkAPI) => {
    const payload = {
      event_type: "ALL",
      from_date: dayjs().format("DD/MM/YY"),
      to_date: dayjs().add(12, "month").format("DD/MM/YY"),
    };
    try {
      const { data } = await axios.get(`${API_URL}/dashboard/calendar`, {
        params: payload,
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message });
    }
  },
);

const calendarSlice = createSlice({
  name: "calendar",
  initialState,
  reducers: {
    setCompaniesSelected(state, { payload }) {
      state.selectedCompanies = payload;
    },
    setEventType(state, { payload }) {
      state.eventType = payload;
    },
    setCountriesSelected(state, { payload }) {
      state.selectedCountries = payload;
    },
    setMarketsSelected(state, { payload }) {
      state.selectedMarkets = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCompanies.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchCompanies.fulfilled, (state, { payload }) => {
      state.status = "success";
      state.companies = [...payload];
    });
    builder.addCase(fetchCompanies.rejected, (state, { error }) => {
      state.status = "failed";
      state.error = error.message;
    });
    builder.addCase(fetchEvents.fulfilled, (state, { payload }) => {
      state.status = "success";
      state.events = sortedDateAsc(
        formatEventsPayload(state.eventType, payload),
      );
    });
    builder.addCase(fetchEvents.rejected, (state, { error }) => {
      state.status = "failed";
      state.error = error.message;
    });
    builder.addCase(fetchAllEvents.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchAllEvents.fulfilled, (state, { payload }) => {
      state.status = "success";
      state.allEvents = sortedDateAsc(formatEventsPayload("ALL", payload));
    });
    builder.addCase(fetchAllEvents.rejected, (state, { error }) => {
      state.status = "failed";
      state.error = error.message;
    });

    builder.addCase(fetchCountries.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchCountries.fulfilled, (state, { payload }) => {
      state.status = "success";
      state.countries = [...payload];
    });
    builder.addCase(fetchCountries.rejected, (state, { error }) => {
      state.status = "failed";
      state.error = error.message;
    });

    builder.addCase(fetchMarkets.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchMarkets.fulfilled, (state, { payload }) => {
      state.status = "success";
      state.markets = [...payload];
    });
    builder.addCase(fetchMarkets.rejected, (state, { error }) => {
      state.status = "failed";
      state.error = error.message;
    });
  },
});

export const {
  setCompaniesSelected,
  setCountriesSelected,
  setMarketsSelected,
  setEventType,
} = calendarSlice.actions;
export default calendarSlice.reducer;
