import * as React from "react";

// Components
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Label } from "@scalens/component-library";

const BasicCard = ({
  children,
  title,
  titleColor = "#001756",
  backgroundColor = "#FFF",
  titleBackground,
  typo = "h6",
  elevation,
  width = "auto",
  fontSize,
  height = "auto",
  titlePadding = "5px 10px",
  fontWeight,
  padding = 1,
  helpText,
  noBorder,
}) => {
  return (
    <Card
      elevation={elevation}
      sx={{
        height,
        flex: 1,
        width,
        overflow: "inherit",
        ...(!noBorder && { borderRadius: ".8rem" }),
      }}
    >
      <Typography
        style={{
          backgroundColor: titleBackground,
          fontSize,
          fontWeight,
          padding: titlePadding,
          display: "flex",
          ...(!noBorder && {
            borderTopLeftRadius: ".8rem",
            borderTopRightRadius: ".8rem",
            border: "none",
          }),
        }}
        variant={typo}
        color={titleColor}
      >
        {title}
        {helpText && <Label helpText={helpText} text="" />}
      </Typography>

      <CardContent
        sx={{
          padding,
          backgroundColor,
          ...(!noBorder && {
            borderBottomLeftRadius: ".8rem",
            borderBottomRightRadius: ".8rem",
            border: "none",
          }),
        }}
      >
        {children}
      </CardContent>
    </Card>
  );
};

export default BasicCard;
