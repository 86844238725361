import { toast } from "react-toastify";

export const toastSuccessHelper = (msg, position = "top-right") => {
  toast.success(msg, {
    position: position,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnFocusLoss: false,
    progress: 0,
  });
};

export const toastErrorHelper = (msg) => {
  toast.error(msg, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    progress: 0,
  });
};
