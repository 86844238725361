import React, { useState } from "react";
import { useSelector } from "react-redux";

// Components
import DocumentUploader from "../../../../../components/DocumentUploader/DocumentUploader";
import BasicModal from "../../../../../components/BasicModal/BasicModal";
import {
  toastErrorHelper,
  toastSuccessHelper,
} from "../../../../../components/Toasts/Toasts";

// Utils
import { httpClientCustomer } from "../../../../../utils/API/httpClient";
import { extractFileType } from "../../../../../utils/helpers";

const ALLOWED_FILE_TYPES = [".xlsx", ".xls", ".doc", ".docx", ".pdf"];

const UploadFeedback = ({ open, handleClose }) => {
  const currentDeal = useSelector((state) => state.deals.currentDeal);

  // Dragger states and variables
  const [fileList, setFileList] = useState([]);
  const [uploadedFilesIds, setUploadedFilesIds] = useState({});

  // Helper functions for dragger methods
  const updateFileList = (updatedFile) => {
    setFileList((prevFileList) => {
      const index = prevFileList.findIndex((f) => f.uid === updatedFile.uid);
      const newFileList = [...prevFileList];
      if (index !== -1) {
        newFileList[index] = updatedFile;
      } else {
        newFileList.push(updatedFile);
      }
      return newFileList;
    });
  };

  function isFileTypeAllowed(file) {
    const fileType = extractFileType(file.name);
    if (!ALLOWED_FILE_TYPES.includes(`.${fileType}`)) {
      toastErrorHelper(`File type ${fileType} is not allowed!`);
      file.status = "error";
      updateFileList(file);
      return false;
    }
    return true;
  }

  function isDealSelected() {
    if (!currentDeal) {
      toastErrorHelper("Please select a company before uploading documents");
      return false;
    }
    return true;
  }

  function prepareFormData(file) {
    const formData = new FormData();
    formData.append("file", file);
    return formData;
  }

  function uploadFileToS3(formData, file) {
    return httpClientCustomer
      .post(`c/${currentDeal.value}/documents/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) =>
          updateFileUploadProgress(file, progressEvent),
      })
      .then((response) => response.data.data); // return fileHash for the next function in the chain
  }

  function uploadFileMetadata(file, fileHash) {
    const payload = {
      file_tag: 1,
      file_hash: fileHash,
      name: file.name,
      description: "investigate_user_upload",
    };
    return httpClientCustomer.post(
      `c/${currentDeal.value}/documents?document-type=file`,
      payload
    );
  }

  function notifyFileUpload(formData, file, response) {
    setUploadedFilesIds((prev) => ({
      ...prev,
      [file.uid]: response.data.data,
    }));
    file.status = "done";
    updateFileList(file);
    toastSuccessHelper(`${file.name} uploaded successfully.`);

    return httpClientCustomer.post(
      `/c/${currentDeal.value}/documents/userupload`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }

  function updateFileUploadProgress(file, progressEvent) {
    const percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );
    file.percent = percentCompleted;
    updateFileList(file);
  }

  function handleFileUploadError(file, error) {
    file.status = "error";
    updateFileList(file);
    const errorMessage =
      error.response && error.response.data.includes("SQLSTATE 23505")
        ? `${file.name} upload failed. File already exists`
        : `${file.name} upload failed.`;
    toastErrorHelper(errorMessage);
  }

  //-----Dragger methods-----
  function beforeUpload(file) {
    if (!isFileTypeAllowed(file) || !isDealSelected()) return false;

    const formData = prepareFormData(file);
    file.status = "uploading";
    updateFileList(file);

    uploadFileToS3(formData, file)
      .then((fileHash) => uploadFileMetadata(file, fileHash))
      .then((uploadMetadataResponse) =>
        notifyFileUpload(formData, file, uploadMetadataResponse)
      )
      .catch((error) => handleFileUploadError(file, error));

    // It's important to return false here if the uploading is handled asynchronously
    // to prevent the default upload behavior of the component (if there is one).
    return false;
  }

  const handleFileChange = ({ file, fileList }) => {
    if (file.status === "done") {
      toastSuccessHelper(`${file.name} uploaded successfully.`);
    } else if (file.status === "error") {
      toastErrorHelper(`${file.name} upload failed.`);
    }
    setFileList(fileList);
  };

  const handleFileRemove = async (file) => {
    if (file.status != "done") {
      setFileList((prevList) => prevList.filter((f) => f.uid !== file.uid));
      return;
    }
    httpClientCustomer
      .delete(
        `c/${currentDeal.value}/documents/${uploadedFilesIds[file.uid]
        }?document-type=file`
      )
      .then((resp) => {
        toastSuccessHelper(`${file.name} removed successfully.`);
      })
      .catch((error) => {
        toastErrorHelper(`Failed to remove ${file.name}.`);
      });
    setFileList((prevList) => prevList.filter((f) => f.uid !== file.uid));
  };

  return (
    <BasicModal
      noSlide
      open={open}
      handleClose={handleClose}
      dialogTitle="Upload Feedback"
      dialogText="Send Scalens your documents for further analysis"
    >
      <div style={{ margin: 20 }}>
        <DocumentUploader
          allowedTypes={ALLOWED_FILE_TYPES}
          beforeUpload={beforeUpload}
          fileList={fileList}
          handleFileChange={handleFileChange}
          handleFileRemove={handleFileRemove}
        />
      </div>
    </BasicModal>
  );
};

export default UploadFeedback;
