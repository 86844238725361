/* eslint-disable no-plusplus */

// Components
import Grid from "@mui/system/Unstable_Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";

const Item = styled(Paper)(() => ({
  fontSize: 15,
  padding: 5,
  textAlign: "left",
  color: "#001756",
  borderRadius: 3,
  height: "auto",
}));

const Header = styled(Paper)(() => ({
  display: "flex",
  alignItems: "center",
  padding: 15,
  fontSize: 15,
  fontWeight: "bold",
  textAlign: "left",
  color: "#001756",
  borderRadius: 3,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  borderTopLeftRadius: 3,
  borderTopRightRadius: 3,
  borderBottom: `1px solid lightgrey`,
}));

const ContainerCard = ({
  headerTitle,
  instruction,
  border,
  style,
  children,
}) => {
  return (
    <Grid
      style={{
        border: border ? "2px solid #001756" : "",
        flex: 1,
      }}
      xs={12}
    >
      <Header
        sx={{
          border: "1px solid lightgrey",
          borderTopRightRadius: "0.8rem",
          borderTopLeftRadius: "0.8rem",
          ...style,
        }}
        elevation={0}
      >
        {headerTitle}
      </Header>
      <Item
        sx={{
          border: "1px solid lightgrey",
          borderBottomLeftRadius: "0.8rem",
          borderBottomRightRadius: "0.8rem",
        }}
        elevation={1}
      >
        {instruction && (
          <Typography
            sx={{
              color: "#001756",
              fontSize: 12,
              marginTop: 3,
            }}
          >
            {instruction}
          </Typography>
        )}
        {children}
      </Item>
    </Grid>
  );
};

export default ContainerCard;
