import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  httpClientCustomer,
  httpClientGlobal,
  httpClientInvestigate,
} from "../../utils/API/httpClient";

export const fetchUser = createAsyncThunk(
  "auth/fetchUser",
  async (_, thunkAPI) => {
    try {
      const {
        data: { data },
      } = await httpClientCustomer.get(`/users/current`);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message });
    }
  },
);

export const initializeAuth = createAsyncThunk(
  "auth/initializeAuth",
  async (_, { dispatch }) => {
    const storedToken = localStorage.getItem("authToken");
    if (storedToken) {
      dispatch(setApiToken(storedToken));
      return storedToken;
    }
    return null;
  }
);

export const initializeUserData = createAsyncThunk(
  "auth/initializeUserData",
  async (user, { dispatch }) => {
    dispatch(
      setCurrentUserId(user?.["https://api.praexo.com/user_metadata"].user_id),
    );
    dispatch(
      setCurrentInvestigateUserId(
        user?.["https://api.praexo.com/user_metadata"].investigate_user_id,
      ),
    );
    const userData = await dispatch(fetchUser()).unwrap();
    return { user, userData };
  },
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    status: "idle",
    error: null,
    userToken: null,
    userId: null,
    userConnected: null,
    investigateUserId: null,
  },
  reducers: {
    setCurrentUserId(state, action) {
      state.userId = action.payload;
    },
    setCurrentInvestigateUserId(state, action) {
      state.investigateUserId = action.payload;
    },
    setApiToken(state, action) {
      const token = action.payload;
      httpClientGlobal.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      httpClientCustomer.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      httpClientInvestigate.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      state.userToken = token;
      localStorage.setItem("authToken", token);
    },
    clearAuth(state) {
      state.userToken = null;
      state.userId = null;
      state.userConnected = null;
      state.investigateUserId = null;
      httpClientGlobal.defaults.headers.common["Authorization"] = null;
      httpClientCustomer.defaults.headers.common["Authorization"] = null;
      httpClientInvestigate.defaults.headers.common["Authorization"] = null;
      localStorage.removeItem("authToken");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.status = "success";
        state.userConnected = action.payload;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(initializeAuth.fulfilled, (state, action) => {
        if (action.payload) {
          state.userToken = action.payload;
        }
      })
      .addCase(initializeUserData.fulfilled, (state, action) => {
        state.userConnected = action.payload.userData;
      });
  },
});

export const {
  setCurrentUserId,
  setCurrentInvestigateUserId,
  setApiToken,
  clearAuth,
} = authSlice.actions;

export default authSlice.reducer;