export const isEmptyObject = (obj) => {
  return Object.values(obj).every((v) => v === null || v === "");
};

export const hasAllZeroValues = (obj) => {
  return Object.values(obj).every((v) => v === "0");
};

export const hasNestedValues = (data) => {
  if (Array.isArray(data)) {
    // used for Announcements/Disputes
    return !data.every(
      (obj) =>
        obj.value === null ||
        obj.value === "" ||
        obj.value === 0 ||
        obj.value === "0",
    );
  }
  // uppercase Value key because that's what comes in from api
  return !Object.values(data).every(
    (v) => v.Value === null || v.Value === "" || v.Value === "00:00:00",
  );
};

export const formatAuM = (number) => {
  return !number ? null : `${number?.toLocaleString("en-US")} M`;
};

export const formatUSD = (number) => {
  return `$${number?.toLocaleString("en-US")}`;
};

export const removeDuplicates = (arr, key) => {
  const values = arr.map((item) => item[key].split(" ").join(""));
  return arr.filter(
    (item, index) => values.indexOf(item[key].split(" ").join("")) === index,
  );
};

//sorting countries alphabetically on monitoring page
export const sortCountries = (countriesArray) =>
  countriesArray.sort((a, b) => {
    if (a.label === "ALL COUNTRIES") return -1;
    if (b.label === "ALL COUNTRIES") return 1;

    return a.label.localeCompare(b.label);
  });

// For use with recharts bar charts
// Remove object with "null" value in "value" key from array of objects
export const filterNullBars = (data) => {
  const filteredArray = [];
  const filtered = Object.entries(data).filter(
    ([_, v]) => v.value !== null && v.value !== "",
  );
  filtered.map((obj) => filteredArray.push(obj[1]));
  return filteredArray;
};

// For use with recharts bar charts
// Create label that appears to the right of the bar on hover (Min/Max)
export const renderHoverLabel = (activeBar) => (props) => {
  const { y, name, value, viewBox } = props;
  const { height, x, width } = viewBox;

  if (name !== activeBar) {
    return null;
  }

  return (
    <g>
      <text
        x={x + width + 120}
        y={y + height / 2 + 1}
        stroke="#9B9B9B"
        fill="#9B9B9B"
        textAnchor="middle"
        dominantBaseline="middle"
        strokeWidth={0.15}
      >
        {value}
      </text>
    </g>
  );
};

// For use with recharts bar charts
// Replace "0" values with a tiny positive value so they render (but don't update label)
export const handleZeroValues = (data) => {
  const updated = data;
  updated.forEach((obj) => (obj.value === "0" ? (obj.value = "0.01") : null));
  return updated;
};

// For use with recharts bar charts (horizontal)
// Fn used to control the maximum width of the highest value bar
// This margin accounts for the space needed to render the bar label
export const renderRightMargin = (barData, rightMargin, chartWidth) => {
  if (barData.every((bar) => bar.value === "0.01")) return chartWidth - 1;
  return rightMargin;
};

// Used to create the fill pattern in the recharts bar and pie charts
// The pattern is created in the chart's <pattern> tag
// The name prop is the <pattern> tag's id value after "pattern-"
export const getStripedPattern = (name) => (
  <svg height="8" width="20">
    <rect
      rx="4px"
      ry="4px"
      height="8"
      width="14"
      fill={`url(#pattern-${name})`}
    />
  </svg>
);

export const createAnnouncements = (data) => {
  // filter the announcements from the KPIs in the data
  const announcementsArray = Object.entries(data).filter(([key, _]) =>
    key.includes("%"),
  );
  const announcementsLabels = {
    "% Companies Announcing Cost Cuts": "Cost Cuts",
    "% Companies Refering To AI Impact": "AI Impact",
    "% Companies Publishing Non Financial KPIs": "Non-Financial KPIs",
    "% Companies Expecting Slowdown": "Expecting Slow Down",
  };
  return announcementsArray.map((subject, i) => ({
    name: announcementsLabels[subject[0]],
    value: subject[1],
  }));
};

export const formatValue = (label, value) => {
  if (label.toLowerCase() === "score" || label.includes("Score Say-on-Pay"))
    return {
      label,
      value: `${value}%`,
    };
  return {
    label,
    value,
  };
};

export const formatDateTime = (dateTime) => {
  return dateTime.toISOString().replace(/-|:|\.\d\d\d/g, "");
};

export const formatAnonymousLabel = (label) => {
  if (label.startsWith("anonymous_")) return "1nonymous feedback";
  return label;
};

export const formatTime = (time) => {
  // The time format should come in as HH:MM:SS
  const hours = time.split(":")[0];
  const minutes = time.split(":")[1];

  if (hours !== "00") {
    return `${hours}h${minutes}`;
  }

  return `${minutes}mn`;
};

export const formatMinMaxTime = (min, max) => {
  return `Min ${formatTime(min)} - Max ${formatTime(max)}`;
};

export const formatMinMaxPercentage = (min, max) => {
  return `Min ${min}% - Max ${max}%`;
};

export const convertToMinutes = (time) => {
  // The time format should come in as HH:MM:SS
  const hours = time.split(":")[0];
  const minutes = time.split(":")[1];
  return hours * 60 + minutes;
};

// calculate the number of months left in the year
export const remainingMonths = () => {
  const currentMonth = new Date().getMonth();
  return 12 - currentMonth;
};

export const sortedDateAsc = (arr) =>
  arr.sort((a, b) => new Date(a.start) - new Date(b.start));

export const truncate = (str, num) => {
  if (str.length <= num) {
    return str;
  }
  if (str) return str.slice(0, num) + "...";
};

export const extractFileType = (fileName) => {
  return fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
};

export const DATA_TYPES = {
  AGENDA: "Agenda",
  AGM: "AGM Report",
  ANNUAL: "Annual Result",
  SEMI_ANNUAL: "Semi-annual Result",
  SHAREHOLDER: "Shareholder Question",
};

export const activityTypes = [
  { id: 0, label: "Call", value: "Call" },
  { id: 1, label: "Meeting", value: "Meeting" },
  { id: 2, label: "Email", value: "Email" },
  { id: 3, label: "Roadshow", value: "Roadshow" },
  { id: 4, label: "Conference", value: "Conference" },
  { id: 5, label: "Other", value: "Other" },
];

export const TIME_ALLOCATION_BAR_COLORS = [
  "#1AAA8E",
  "#1ABC9C",
  "#48C9B0",
  "#76D7C4",
  "#D1F2EB",
  "#D6EAF8",
  "#AED6F1",
  "#85C1E9",
  "#5DADE2",
  "#3498DB",
];

// NOTE: pieColors and legendColors need to match - colors and order of colors
// this prevents needing to add classes to a tailwind safelist (not the best for maintenance)
// unfortunately legendColors cannot be created via a map, tailwind doesn't always like string interpolication
export const SHAREHOLDER_QS_BAR_COLORS = [
  "#3498DB",
  "#5DADE2",
  "#9ECEEE",
  "#76D7C4",
  "#1ABC9C",
  "#EDEDED",
];

export const SHAREHOLDER_QS_LEGEND_COLORS = [
  "bg-[#3498DB]",
  "bg-[#5DADE2]",
  "bg-[#9ECEEE]",
  "bg-[#76D7C4]",
  "bg-[#1ABC9C]",
  "bg-[#EDEDED]",
];

export const ANNOUNCEMENTS_BAR_COLORS = [
  "#48C9B0",
  "#76D7C4",
  "#85C1E9",
  "#5DADE2",
];

export const GUIDANCE_CONTENT_BAR_COLORS = [
  "#3498DB",
  "#5DADE2",
  "#85C1E9",
  "#76D7C4",
  "#48C9B0",
];

export const GUIDANCE_CONTENT_LEGEND_COLORS = [
  "bg-[#3498DB]",
  "bg-[#5DADE2]",
  "bg-[#85C1E9]",
  "bg-[#76D7C4]",
  "bg-[#48C9B0]",
];

export const INSTRUMENTS = [
  { id: 1, datakey: "Mutual Fund", color: "#2980b9" },
  { id: 2, datakey: "Pension Fund", color: "#82a0bf" },
  { id: 3, datakey: "Hedge Fund", color: "#3498db" },
  { id: 4, datakey: "Holding Company", color: "#045089" },
  { id: 5, datakey: "Private Banking/Wealth Mgmt", color: "#4AB886" },
  { id: 6, datakey: "Unknow", color: "#70a1ff" },
  { id: 7, datakey: "SWF", color: "#19305b" },
  { id: 8, datakey: "Investment Adviser", color: "#259595" },
  { id: 9, datakey: "Insurance Company", color: "#21c3c3" },
  { id: 10, datakey: "Market Maker", color: "#202c60" },
  { id: 11, datakey: "Sovereign Wealth Manager", color: "#4f5a8a" },
  { id: 12, datakey: "Broker", color: "#3d5ce5" },
  { id: 13, datakey: "Foundation/Endowment Manager", color: "#6b7189" },
  { id: 14, datakey: "Family Office", color: "#573dc0" },
  { id: 15, datakey: "Bank Investment Division", color: "#7d65dc" },
  { id: 16, datakey: "Real Estate Manager", color: "#9d58c5" },
  { id: 17, datakey: "Fund of Hedge Funds Manager", color: "#7931a2" },
];

export const OPINIONS = [
  { id: 0, datakey: "n/a", color: "#B7B7B7" },
  { id: 1, datakey: "Poor", color: "#82a0bf" },
  { id: 2, datakey: "Average", color: "#3498db" },
  { id: 3, datakey: "Good", color: "#4AB886" },
  { id: 4, datakey: "Outstanding", color: "#9b59b6" },
];

export const TIERS = [
  { id: 1, label: "Tier 1", name: "tier_1", defaultChecked: true },
  { id: 2, label: "Tier 2", name: "tier_2", defaultChecked: true },
];

export const NEWSFEEDS_TYPE = {
  callReport: 6,
  feedback: 7,
};

export const MULTIPLES_MOCK = [
  { id: 0, name: "EV/Adj EBITDA" },
  { id: 1, name: "EV/Adj EBITA" },
  { id: 2, name: "Adj PER" },
];

export const MONITOR_PILL_COLORS = {
  Agenda: "#CFC923",
  AGM: "#9C00F6",
  "FY Result": "#3498DB",
  "H1 Result": "#1ABC9C",
  "Shareholder Question": "#FF7979",
  Scalens: "#001756",
};

export const getEventColor = (type) => {
  switch (type) {
    case "AGM":
      return "#9b59b6";
    case "H1 Results":
      return "#1AAA8E";
    case "FY Results":
      return "#3498DB";
    default:
      throw new Error("Invalid event type");
  }
};

export const formatRatingPayload = (val) => (val === -1 ? null : val);

export const capitalize = (str) =>
  `${str?.charAt(0).toUpperCase()}${str?.slice(1).toLowerCase()}`;

export const titleCase = (str) =>
  str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());

export const COUNTRY_FLAGS = {
  australia: "flag-au",
  belgium: "flag-be",
  canada: "flag-ca",
  denmark: "flag-dk",
  finland: "flag-fi",
  france: "flag-fr",
  germany: "flag-de",
  greece: "flag-gr",
  india: "flag-in",
  italy: "flag-it",
  netherlands: "flag-nl",
  norway: "flag-no",
  spain: "flag-es",
  sweden: "flag-se",
  switzerland: "flag-ch",
  uk: "flag-uk",
  "united states": "flag-us",
};

export const EVENT_TYPE = [
  { id: 1, label: "All", value: "ALL" },
  { id: 2, label: "AGM", value: "ASSEMBLY" },
  { id: 3, label: "FY Results", value: "ANNUAL_RESULT" },
  { id: 4, label: "H1 Results", value: "SEMI_ANNUAL_RESULT" },
];

export const formatEventsPayload = (type, payload) => {
  switch (type) {
    case "ALL":
      return payload.items.length > 0
        ? payload.items.map((ev) => ({
          id: crypto.randomUUID(),
          title: ev.DENOMINATION,
          Country: ev.COUNTRY,
          company: ev.DENOMINATION,
          logo: ev.IMAGE,
          Type:
            ev.RESULT_TYPE === "Résultats annuels"
              ? "FY Results"
              : ev.RESULT_TYPE === "Résultats semestriels"
                ? "H1 Results"
                : "AGM",
          start: new Date(ev.DATE_ASSEMBLY || ev.PUBLICATION_DATE),
          end: new Date(ev.DATE_ASSEMBLY || ev.PUBLICATION_DATE),
        }))
        : [];
    case "ASSEMBLY":
      return payload.items.length > 0
        ? payload.items.map((ev) => ({
          id: crypto.randomUUID(),
          title: ev.DENOMINATION,
          Country: ev.COUNTRY,
          company: ev.DENOMINATION,
          logo: ev.IMAGE,
          Type: "AGM",
          start: new Date(ev.DATE_ASSEMBLY),
          end: new Date(ev.DATE_ASSEMBLY),
        }))
        : [];
    case "ANNUAL_RESULT":
      return payload.items.length > 0
        ? payload.items.map((ev) => ({
          id: crypto.randomUUID(),
          title: ev.DENOMINATION,
          Type: "FY Results",
          start: new Date(ev.PUBLICATION_DATE),
          end: new Date(ev.PUBLICATION_DATE),
        }))
        : [];
    case "SEMI_ANNUAL_RESULT":
      return payload.items.length > 0
        ? payload.items.map((ev) => ({
          id: crypto.randomUUID(),
          title: ev.DENOMINATION,
          Type: "H1 Results",
          start: new Date(ev.PUBLICATION_DATE),
          end: new Date(ev.PUBLICATION_DATE),
        }))
        : [];
    default:
      throw new Error();
  }
};

export const DASHBOARD_TABS = [
  { id: crypto.randomUUID(), label: "All", value: 0 },
  { id: crypto.randomUUID(), label: "Feedback", value: 1 },
  // { id: crypto.randomUUID(), label: "Reports", value: 2 },
  { id: crypto.randomUUID(), label: "Charts", value: 2 },
  { id: crypto.randomUUID(), label: "Governance News", value: 3 },
];

export const DASHBOARD_TAB_LABELS = {
  ALL: 0,
  FEEDBACKS: 1,
  // REPORTS: 2,
  CHARTS: 2,
  NEWS: 3,
};

export const SCALENS_IR_TABS = [
  { id: crypto.randomUUID(), label: "Feedback summary", value: 0 },
  { id: crypto.randomUUID(), label: "My feedback", value: 1 },
  // { id: crypto.randomUUID(), label: "Company info", value: 2 },
  // { id: crypto.randomUUID(), label: "Shareholder's questions", value: 3 },
];

export const SCALENS_IR_TAB_LABELS = {
  SUMMARY: 0,
  MY_FEEDBACKS: 1,
  // COMPANY_INFOS: 2,
  // QUESTIONS: 3,
};

export const CRM_TABS = [
  { id: crypto.randomUUID(), label: "My Contacts", value: 0 },
  { id: crypto.randomUUID(), label: "My Meetings", value: 1 },
  { id: crypto.randomUUID(), label: "My Events", value: 2 },
  { id: crypto.randomUUID(), label: "Investor Database", value: 3 },
];

export const CRM_TAB_LABELS = {
  MY_CONTACTS: 0,
  MY_MEETINGS: 1,
  MY_EVENTS: 2,
  INVESTOR_DATABASE: 3,
};

export const CRM_MEETING_TYPES = [
  "Conference Group",
  "Group Meeting",
  "One-to-Few",
  "One-to-One",
  "Other",
];

export const CRM_EVENT_TYPES = [
  "Conference",
  "Extraordinary General Meeting",
  "One-to-One",
  "Perception Analysis",
  "Other",
];

export const formatDateFromString = (dateString) => {
  const [year, month, day] = dateString.split("-");
  return new Date(year, parseInt(month) - 1, day);
};

export const sortDataByDate = (data) => {
  return data.sort((a, b) => new Date(b.sortedDate) - new Date(a.sortedDate));
};

export const getNotifText = (key) => {
  switch (key) {
    case NOTIFICATION_TYPE.FEEDBACK:
      return "provided feedback";
    case NOTIFICATION_TYPE.FEEDBACK_2:
      return "provided feedback";
    case NOTIFICATION_TYPE.COMMENT:
      return "has commented";

    default:
      return "";
  }
};

export const INVESTOR_TYPES = [
  { id: 0, label: "All" },
  { id: 1, label: "Mutual Fund" },
  { id: 2, label: "Pension Fund" },
  { id: 3, label: "Hedge Fund" },
  { id: 4, label: "Holding Company" },
  { id: 5, label: "Private Banking/Wealth Mgmt" },
  { id: 6, label: "Unknown" },
  { id: 7, label: "SWF" },
  { id: 8, label: "Investment Adviser" },
  { id: 9, label: "Insurance Company" },
  { id: 10, label: "Market Maker" },
  { id: 11, label: "Sovereign Wealth Manager" },
  { id: 12, label: "Broker" },
  { id: 13, label: "Foundation/Endowment Manager" },
  { id: 14, label: "Family Office" },
  { id: 15, label: "Bank Investment Division" },
  { id: 16, label: "Real Estate Manager" },
  { id: 17, label: "Fund of Hedge Funds Manager" },
];

export const MGMT_STYLES = [
  { id: 0, label: "All" },
  { id: 1, label: "AGRESSIVE GROWTH" },
  { id: 2, label: "DEEP VALUE" },
  { id: 3, label: "GARP" },
  { id: 4, label: "GROWTH" },
  { id: 5, label: "INDEX" },
  { id: 6, label: "N/A" },
  { id: 7, label: "VALUE" },
  { id: 8, label: "YIELD" },
];

export const NOTIFICATION_TYPE = {
  FEEDBACK: 1,
  FEEDBACK_2: 5,
  COMMENT: 2,
};

export const TAG_COLORS = {
  backgroundColor: "#C2D7E6",
  textColor: "#001756",
};
