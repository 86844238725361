import React, { useCallback } from "react";
import { useForm } from "react-hook-form";

// Components
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import FeedbackForm from "../Forms/FeedbackForm";
import ActivityReportForm from "../Forms/ActivityReportForm";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import { toastErrorHelper, toastSuccessHelper } from "../Toasts/Toasts";

// Hooks
import * as R from "ramda";
import moment from "moment";
import { useFetchFieldKinds } from "../../hooks/useFetchFieldKinds";
import { formatRatingPayload } from "../../utils/helpers";
import { httpClientGlobal } from "../../utils/API/httpClient";

export const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FullDialog = ({ open, handleClose }) => {
  const [fieldKinds] = useFetchFieldKinds();

  const {
    formState: { errors },
    control,
    reset,
    handleSubmit,
  } = useForm({
    defaultValues: {
      investorName: { label: "", value: "" },
      firstName: "",
      lastName: "",
      email: "",
      activityType: { label: "", value: "" },
      activityDate: "",
      isInvested: "",
      isWillingToBuy: "",
      nbShares: 0,
      executionStrategy: -1,
      financialPosition: -1,
      qualityManagement: -1,
      strengths: [],
      weaknesses: [],
      positiveInfluence: [],
      negativeInfluence: [],
      mngChallenges: [],
      riskFactors: [],
      opinionOnValuation: "n/a",
      valuationMethodologies: [],
      peers: [],
      event: { label: "", value: "" },
    },
  });

  const handleCloseModal = useCallback(() => {
    handleClose();
    reset();
  }, [handleClose, reset]);

  const getFieldKind = useCallback(
    (key) => {
      return fieldKinds.find((fk) => key === fk.key);
    },
    [fieldKinds],
  );

  const onSubmit = () => {
    handleSubmit(
      ({
        investor,
        opinionOnValuation,
        executionStrategy,
        financialPosition,
        qualityManagement,
        strengths,
        weaknesses,
        positiveInfluence,
        negativeInfluence,
        activityType,
        activityDate,
        firstName,
        lastName,
        email,
        isWillingToBuy,
        isInvested,
        nbShares,
        peers,
        mngChallenges,
        riskFactors,
        valuationMethodologies,
        event,
      }) => {
        const fp = formatRatingPayload(financialPosition);
        const es = formatRatingPayload(executionStrategy);
        const qm = formatRatingPayload(qualityManagement);
        const payload = {
          event_id: event.value,
          interaction: {
            kind: activityType?.value,
            date: moment(activityDate, "YYYY-MM-DD").toISOString(),
            contact: {
              company_id: investor?.value,
              first_name: firstName,
              last_name: lastName,
              email,
              is_willing_to_buy: isWillingToBuy === "Yes",
              is_invested: isInvested === "Yes",
              shares_number: Number(nbShares),
            },
          },
          fields_1d: [
            {
              field_kind_id: getFieldKind("opinion-on-value")?.id,
              value: opinionOnValuation,
            },
            ...(!R.isNil(fp)
              ? [
                  {
                    field_kind_id: getFieldKind("financial-position")?.id,
                    value: fp,
                  },
                ]
              : []),
            ...(!R.isNil(es)
              ? [
                  {
                    field_kind_id: getFieldKind("execution-strategy")?.id,
                    value: es,
                  },
                ]
              : []),
            ...(!R.isNil(qm)
              ? [
                  {
                    field_kind_id: getFieldKind("quality-management")?.id,
                    value: qm,
                  },
                ]
              : []),
            ...negativeInfluence.map((ni) => ({
              field_kind_id: getFieldKind("negative-influence")?.id,
              value: ni.value,
            })),
            ...positiveInfluence.map((pi) => ({
              field_kind_id: getFieldKind("positive-influence")?.id,
              value: pi.value,
            })),
            ...strengths.map((str) => ({
              field_kind_id: getFieldKind("strength")?.id,
              value: str.value,
            })),
            ...weaknesses.map((weakness) => ({
              field_kind_id: getFieldKind("weakness")?.id,
              value: weakness.value,
            })),
            ...mngChallenges.map((mc) => ({
              field_kind_id: getFieldKind("mng-challenge")?.id,
              value: mc.value,
            })),
            ...riskFactors.map((rf) => ({
              field_kind_id: getFieldKind("risk-factor")?.id,
              value: rf.value,
            })),
            ...valuationMethodologies.map((vm) => ({
              field_kind_id: getFieldKind("valuation-methodologie")?.id,
              value: vm.value,
            })),
          ],
          fields_2d: [
            ...peers.map((peer) => ({
              field_kind_id: getFieldKind("peer")?.id,
              x_axis: peer.date_of_peer_investment,
              y_axis: peer.name,
            })),
          ],
        };

        httpClientGlobal
          .post(`/forms/interaction-feedback`, payload)
          .then(() => {
            toastSuccessHelper("Feedback sent!");
            reset();
            handleClose();
          })
          .catch((err) => {
            toastErrorHelper("An error occured while sending feedback");
          });
      },
    )();
  };

  return (
    <Dialog
      sx={{
        "& .MuiPaper-root": {
          width: "100%",
          maxWidth: 850,
        },
        backdropFilter: "blur(3px)",
      }}
      open={open}
      onClose={handleCloseModal}
      TransitionComponent={Transition}
    >
      <div className="pb-8 bg-[#f1f1f8]">
        <CssBaseline />
        <Container fixed>
          <ActivityReportForm
            control={control}
            errors={errors}
            handleClose={handleCloseModal}
          />
          <CssBaseline />
          <FeedbackForm control={control} errors={errors} />
        </Container>
        <Button
          style={{
            width: 120,
            fontWeight: "bold",
            fontSize: 13,
            color: "#FFF",
            backgroundColor: "#001756",
            float: "right",
            marginRight: "1.5rem",
          }}
          endIcon={<SendIcon />}
          onClick={onSubmit}
        >
          send
        </Button>
      </div>
    </Dialog>
  );
};

export default FullDialog;
