import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tiers: [
    {
      id: 1,
      label: "Tier 1",
      name: "tier_1",
      value: true,
      defaultChecked: true,
    },
    {
      id: 2,
      label: "Tier 2",
      name: "tier_2",
      value: true,
      defaultChecked: true,
    },
  ],
};

const tierSlice = createSlice({
  name: "tiers",
  initialState,
  reducers: {
    setTier(state, { payload }) {
      const { name, value } = payload;
      const tier = state.tiers?.find((t) => t.name === name);
      if (tier) {
        tier.value = value;
      }
    },
  },
});

export const { setTier } = tierSlice.actions;
export default tierSlice.reducer;
